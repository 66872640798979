import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { green, grey, orange, red } from "@mui/material/colors";
import { adaptV4Theme, createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import Amplify from "aws-amplify";
import 'fontsource-roboto';
import { SnackbarProvider } from "notistack";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import awsconfig from "./aws-exports";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import './wdyr'; // <--- first import

Amplify.configure(awsconfig);


const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      light: "#f26822",
      main: "#f26822",
      dark: "#f26822",
      contrastText: "#fff",
    },
    secondary: grey,
    success: {
      light: green[300],
      main: green[500],
      dark: green[700],
      contrastText: "#fff",
    },
    warning: {
      light: orange[500],
      main: orange[700],
      dark: orange[900],
      contrastText: "#fff",
    },
    red: {
      light: red[500],
      main: red[700],
      dark: red[900],
      contrastText: "#fff",
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.3,
    background: {
      default: grey[200],
    },
  },
}));

const LazyAuthenticator = lazy(() =>
  import("./codestorage_cms_V4/authentication/CommonAuth/CommonAuthenticator")
);

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Suspense fallback={<div />}>
                <LazyAuthenticator />
              </Suspense>
            </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>

  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
